<template>
  <div :class="bgClass">
   
<b-container>
  <b-row><b-col>
    <p></p>  <h1>  <img :src="getImgUrl('simplysudoku_k.png')" width="100" height="100" class="d-inline-block align-top" alt="">
Simply Sudoku</h1>
  </b-col></b-row>
  <b-row>
    <b-col cols="2"></b-col>
    <b-col cols="5"> <Grid /></b-col>
    <b-col cols="3" class="justify-content-md-center">
       <b-row>
        <b-col>
            <div class="keycontainer">
      <Keyboard @pressed="value = $event" :selfValue="value"></Keyboard>
    </div>
        
       </b-col></b-row> 
      <b-row>

        <b-col>
          <div class="keycontainer bg-light rounded border">
          <b-row>
            <b-col> <div>
              <!--<div class="row">
                <input type="text" placeholder="Timer name" />
              </div>-->
              <div> <p><strong>Time</strong></p>
                      {{displayTime}} </div>
             
            </div></b-col>
            <b-col> <div>
              <!--<div class="row">
                <input type="text" placeholder="Timer name" />
              </div>-->
              <div> <p><strong>Last</strong></p>{{lastTime}} </div>
             
              
            </div></b-col>
            <b-col> <div>
              <!--<div class="row">
                <input type="text" placeholder="Timer name" />
              </div>-->
              <div><p><strong>Best</strong> </p>{{bestTime}} </div>
              
            </div></b-col>
            
          </b-row>
          <b-row><b-col><div>
                <b-button size="sm" @click="startPause">{{ isRunning ? 'Pause' : 'Resume'}}</b-button>
               
               <!--<button @click="reset" class="ghost">Reset</button>-->
              </div></b-col></b-row>
              </div>
                 <div>
         
    </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p></p>
      <b-form-select v-model="difficulty"> 
        <option v-for="(difficulty,idx) in difficulties" :key="idx">{{difficulty}}</option>
      </b-form-select> 
      <p><b-button size="sm"  @click="newG=true">New Game</b-button></p>
     
        </b-col>
      </b-row>
     <b-row>
       <b-col>
   
        <div><p></p>
            <p><strong>Goal of the game :</strong></p>
              <ol>
              <li>No duplicates of the number 1-9 horizontally.</li>
              <li>No duplicates of the number 1-9 vertically.</li>
              <li>No duplicates of the number 1-9 in the 3X3 cell groups.</li>
              Select cell to input number with buttons or keyboard.
            </ol> 
          </div>
       </b-col>
     </b-row>
      </b-col>
    <b-col cols="2"></b-col>
  </b-row>
    <b-modal v-model="wonGame" centered title="You Won" ok-only><div class="wonGame">You Solve Puzzle</div>
    <p>you solved in <strong> {{displayTime}}</strong></p>

      </b-modal>
  <b-modal v-model="newG" centered title="New Game" ok-only><div class="justify-content-md-center" > <div class="wonGame">Want to quit current puzzle and start a new one</div>
    <p> <b-button class="mt-2" variant="success" @click="newGame">Yes</b-button>
  
     <b-button class="mt-2" variant="success" @click="newG=false">No</b-button>
    </p>
</div>
      </b-modal>
      <p></p>
</b-container>
 
 
   
   
 

    </div>
</template>

<script>
import { mapState } from 'vuex';
import Grid from '@/components/Grid';
import Keyboard from '@/components/Keyboard';
import { formatNumber } from '../utils/funcs';
const STOPPED = 1, STARTED = 2, PAUSED = 3;

export default {
  name: 'Home',
  components: {
    Grid,
    Keyboard,
 
  },
 data() {
    return {
      difficulty: null,
      value:'',
      //timerlar
      state: STOPPED,
      seconds: 0,
      prevSeconds: 0,
      intervalId: null,
      startTime: null,
      lastTime: 0,
      bestTime: 0,
      gameTime: 0,
      newG:false,
       //timerson
    }
  },
  computed:{ ...mapState(['difficulties', 'wonGame']),
          timerClass() {
            return "timer " + (this.isRunning ? 'running' : 'paused')
          },
          bgClass() {
            return "bg " + (this.isRunning ? 'bg1' : 'bg2')
          },
          displayTime() {
            let x = Math.floor(this.seconds + this.prevSeconds);
            let s = x % 60;
            x = (x - s) / 60;
            let m = x % 60;
            let h = (x - m) / 60;
            if( h === 0 )
              return formatNumber(m) + ':' + formatNumber(s)
            return `${formatNumber(h)}:${formatNumber(m)}:${formatNumber(s)}`
          },
          isRunning() { return this.state === STARTED }
        },
  created() {
    this.$store.commit('initGrid');
    this.difficulty = this.difficulties[1];
    this.stats();
    this.newGame();
  },
  methods: {
      getImgUrl(pic) {
      return require("../assets/" + pic);
       },
  
    formatTime(time) {
            let x = Math.floor(time);
            let s = x % 60;
            x = (x - s) / 60;
            let m = x % 60;
            let h = (x - m) / 60;
            if( h === 0 )
              return formatNumber(m) + ':' + formatNumber(s)
            return `${formatNumber(h)}:${formatNumber(m)}:${formatNumber(s)}`
          },
    setNumber(){
      this.$store.commit('setNumber', this.value);
    },
    stats(){
      this.lastTime = this.formatTime(localStorage.getItem('fSudokuLasttime'));
      this.bestTime = this.formatTime(localStorage.getItem('fSudokuBesttime'));
       if(this.lastTime===null){
        this.lastTime = 0;
       }

       //console.log('lastTime',this.lastTime);
       // console.log('bestTime',this.bestTime);

       },
    newGame() {
      this.$store.commit('initGrid', this.difficulty);
      this.reset();
      this.start();
      this.state = STARTED;
      this.newG=false;
    },
   
		getNewId(oldIds) {
			let id;
			do {
				id = Math.random();
			} while( oldIds.some(i => i === id) );
			return id;
		},
    reset() {
			this.stop();
			this.seconds = 0;
			this.prevSeconds = 0;
		},
		startPause() {
			if( this.state === STOPPED || this.state === PAUSED ) {
				this.start();
			} else {
				this.stop();
			}
		},
		start() {
			this.startTime = (new Date()).getTime();
			this.state = STARTED;
			this.intervalId = window.setInterval(() => {
				let now = (new Date()).getTime()
				this.seconds = (now - this.startTime) / 1000;
				// console.log('interval', this.seconds);
			}, 250)
		},
		stop() {
			this.prevSeconds += this.seconds;
			this.seconds = 0;
			this.state = STOPPED;
			window.clearInterval(this.intervalId);
			this.intervalId = null;
			this.startTime = null;
		}
  },
  watch: {
    wonGame(newVal) {
      if( newVal==true ) {
        this.gameTime = this.seconds + this.prevSeconds;
        localStorage.setItem('fSudokuLasttime', this.gameTime);
        
        if(this.gameTime < this.lastTime){
          localStorage.setItem('fSudokuBesttime', this.gameTime);
        }
        if(this.bestTime==0){
               localStorage.setItem('fSudokuBesttime', this.lastTime);
    
        }
        

      }
    }
  }
}
</script>

<style scoped>
.timer-btns {
  border:none;
  color: #ccc;
  font-size:1em;
  background-color: transparent;
  cursor: pointer;
 
}
.timer-btns:hover {
    color: #888;
    }
.running {
	background-color:lightgray;
}
.paused {
	background-color: lightgray;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
.wonGame {
  color: green;
  text-decoration: underline;
}  
.keycontainer {
  padding-bottom: 20px;
  max-width: 300px;
}
button {
	margin: 0.3em;
	padding: 0.7em 3.5em;
	font-size: 1em;
	border-radius: 0.4em;

	border-color: #1189AF;
	transition: all 0.6s;
}
button.ghost {
	background-color: white;
}
button:hover {
	background-color:gainsboro;
	cursor: pointer;
  color: black;
	/* border-color: #1189AF */
}
.bg {
  background-color: #DEEBED;
  height: 100%;
  width: 100%;
}
.bg1 {
  background-color: #ffffff;
}
.bg2 {
  background-color: #ffffff;
}
</style>
