import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueGtag from 'vue-gtag'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
//import Ads from 'vue-google-adsense'
import VueMeta from 'vue-meta'
Vue.config.productionTip = false

Vue.use(require('vue-script2'))

//Vue.use(Ads.Adsense)
//Vue.use(Ads.InArticleAdsense)
//Vue.use(Ads.InFeedAdsense)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMeta)
  //analytic id: 'G-D4RN031XYP',
Vue.use(VueGtag,{
  config:{ id:"G-D4RN031XYP"}
}, router)



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
