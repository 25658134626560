<template>
  <div>
    <table>
      <tbody>
      <tr v-for="(row,idx) in grid" :key="idx">
        <td v-for="(cell,idy) in row" :key="idy" 
        :class="{ 
          locked: grid[idx][idy].locked, 
          selected:grid[idx][idy].selected,
          error:grid[idx][idy].error
        }"
        @click="setSelected(grid[idx][idy], idx, idy)"> {{ grid[idx][idy].value }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Grid',
  computed: mapState([
    'grid'
  ]),
  methods: {
    pickNumber(e) {
      let typed = parseInt(String.fromCharCode(e.keyCode),10);
      // if it was NaN, split out
      if(!typed) return;
      this.$store.commit('setNumber', typed);
    },
    setSelected(cell,x,y) {
      this.$store.commit('setSelected',{x,y});
    }
  },
  mounted() {
    window.addEventListener('keypress', this.pickNumber);
  },
  destroyed() {
    window.removeEventListener('keypress', this.pickNumber);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  
  border-collapse: collapse;
  border: 1px solid;
   margin: 10px;
   font-weight: bold;
   
}

td {
  border: 1px solid lightgrey;
	text-align: center;
  height: 55px;
  width: 55px;
 
}

table tbody tr td:nth-child(3), table tbody tr td:nth-child(6) {
  border-right: 2px solid;
}

table tbody tr:nth-child(3), table tbody tr:nth-child(6) {
  border-bottom: 2px solid;
}

td.locked {
    font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
  cursor: not-allowed;
  background-color:rgb(243, 239, 240);
}

td {
  cursor: pointer;
}


td.selected {
  background-color: rgb(229, 240, 178);
  border: 2px solid rgb(4, 34, 68);
  border-radius: 4px;
}

td.error {
  color: white;
   background-color:red;
 
}


</style>
