<template>
  <div id="app">
     <b-navbar type="dark" variant="dark">
       
    <b-navbar-brand to="/">    <img :src="getImgUrl('simplysudoku_k.png')" width="40" height="40" class="d-inline-block align-center" alt="">
Simply Sudoku</b-navbar-brand>
      <b-navbar-nav>
          <b-nav-item to="/">Play Sudoku</b-nav-item>
         <b-nav-item to="/howto">How To Play Sudoku</b-nav-item>
           <b-nav-item to="/about">History of Sudoku</b-nav-item>
       <b-nav-item to="/tech">Sudoku Solving Techniques</b-nav-item>
       <b-nav-item href="https://simply-mahjong.com" target="_new">Play Mahjong</b-nav-item>
  
      </b-navbar-nav>
  </b-navbar>
    <router-view/>
  <p>
   
  </p>
   <p></p>
    <p></p>
    <br />
     <br />
        <footer>
          <!-- Copyright -->
        <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
          © 2021 Copyright:
          <a class="text-reset fw-bold" href="https://www.free-sudoku.net/">Simply Sudoku</a>
        || <router-link to="/policy">Privacy Policy</router-link>
        </div>
        <!-- Copyright -->
      </footer>
      </div>
</template>
<script>
export default {
  name: "App",
   metaInfo: {
      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
         { name: 'description', content: 'Simply Sudoku is web site for play online free sudoku, mahjon and solitaire game. Plain and simple simply free online sudoku.' }
  
    ],
     // if no subcomponents specify a metaInfo.title, this title will be used
     title: 'Simply Free Sudoku | Play free online sudoku, mahjong, solitaire ',
     // all titles will be injected into this template
     titleTemplate: '%s | Simply Free Sudoku'
   },
  setup(){
      return{
         }
  },
  methods: {
      getImgUrl(pic) {
      return require("./assets/" + pic);
       }
  }

  
}
</script>

<style>
#app {
 font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 40px;
}

#nav a {
  font-weight: bolder;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
